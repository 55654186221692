import React, { useEffect } from 'react'
import { Box, Icon, Typography, useTheme, MonocerosTheme, Brand } from '@lbg-protection/lib-ui-monoceros'
import { getIllustrationSize, isLloyds, isSW } from '../../../utils/brandSpecificFormatting'
import { PhoneNumberLink } from '../PhoneNumberLink/PhoneNumberLink'
import { styles } from './styles'
import { useTagging } from '../../../analytics/useTagging'

export const TechnicalError = () => {
  const classes = styles()
  const theme = useTheme<MonocerosTheme>()
  const isSWBrand = isSW(theme.name as Brand)
  const iconName = isLloyds(theme.name as Brand) || isSWBrand ? 'Spanner-illustration' : 'Tools-illustration'

  let illustrationColor
  if (isLloyds(theme.name as Brand)) {
    illustrationColor = theme.palette.namedColours?.lloydsBrightGreen
  } else if (isSWBrand) {
    illustrationColor = theme.palette.namedColours?.orionTechnicalErrorRed
  } else {
    illustrationColor = theme.palette.namedColours?.halifaxCoreBlue
  }

  const { tagErrorScreen, tagTechnicalErrorPageLoad } = useTagging()
  const content = (
    <Box className={classes.contentContainer}>
      <Typography className={classes.subHeader} data-testid="technical-error-subtitle">
        We can&apos;t do what you&apos;ve asked right now due to an error on our end.
      </Typography>
      <Typography className={classes.text} data-testid="technical-error-body">
        Please try again later. If you need more info, give us a call. We&apos;re happy to help.
      </Typography>
      <PhoneNumberLink gutterBottom data-testid="error-phone-link" department="modelOffice" />
      <Typography className={classes.text}>Lines are open 9am to 5pm, Monday to Friday.</Typography>
    </Box>
  )

  const boxProps = {
    ...(isSWBrand && { maxWidth: `${theme.breakpoints.values.sm}px` }),
    className: classes.swBox,
  }
  useEffect(() => {
    tagErrorScreen()
    tagTechnicalErrorPageLoad()
  }, [tagErrorScreen, tagTechnicalErrorPageLoad])
  return (
    <>
      <Box data-testid="technical-error-container" className={classes.container}>
        <Box {...boxProps}>
          <Icon
            className={classes.icon}
            name={iconName}
            htmlColor={illustrationColor}
            size={getIllustrationSize(theme.name as Brand)}
            data-testid="technical-error-icon"
          />
          <Typography variant="h4" component="h1" className={classes.text} data-testid="technical-error-title">
            Sorry, there&apos;s been an error
          </Typography>
          {content}
        </Box>
      </Box>
    </>
  )
}
