import { AboutYouProductHolding as AboutYouAccount } from '@lbg-protection/api-client-nmp-journey'
import { Selector } from '@reduxjs/toolkit'

export enum PrePurchaseJourneyRouteEnum {
  PathFinder = 'PathFinder',
  StraightToQuote = 'StraightToQuote',
  Learn = 'Learn',
}

export enum CoverSelectedEnum {
  None = 'NONE',
  Life = 'LIFE',
  Body = 'BODY',
  All = 'ALL',
}

export interface CoverAmountInterface {
  sumAssured: number
  term: number
}

export interface PrePurchaseInterface {
  userRoute: PrePurchaseJourneyRouteEnum
  coverSelected: CoverSelectedEnum
  life?: CoverAmountInterface
  body?: CoverAmountInterface
}

export type PrePurchaseState = PrePurchaseInterface | {}

export interface CoverChoiceInterface {
  coverSelected: CoverSelectedEnum
  life?: CoverAmountInterface
  body?: CoverAmountInterface
}

export interface AddressInterface {
  postCode: string
  subBuildingName: string
  buildingName: string
  buildingNumber: string
  addressLines: string[]
  district: string
  postTown: string
  county: string
}

export interface ApplicantInfoInterface {
  title: string
  firstName: string
  lastName: string
  dateOfBirth: Date
}

export interface AddressResult {
  address: AddressInterface
  summary: string
}

export type SelectPrePurchase = Selector<PrePurchaseState, PrePurchaseInterface>
export type SelectCoverChoice = Selector<PrePurchaseState, CoverChoiceInterface>
export type SelectUserRoute = Selector<PrePurchaseState, PrePurchaseJourneyRouteEnum>

export type AboutYouProductHolding = Omit<AboutYouAccount, 'sortCode' | 'accountNumber'> & {
  sortCode: string
  accountNumber: string
}
