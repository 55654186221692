import { createAsyncThunk, createSlice, Selector, createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../app/types'
import { policyApi } from './policyApi'
import { CreatePolicyApplicationParams, CreatePolicyApplicationCallStatus } from './types'

export const createPolicyApplication = createAsyncThunk(
  'policy/create',
  async (args: CreatePolicyApplicationParams, { getState }): Promise<void> => {
    const {
      saveAndRetrieve: { lastQuoteDate },
    } = getState() as RootState

    await policyApi.createPolicyApplication({ ...args, lastQuoteDate })
  },
)

type SelectPolicyCallStatus = Selector<Record<string, string>, CreatePolicyApplicationCallStatus>

export const selectPolicyCallStatus: SelectPolicyCallStatus = createSelector(
  (state) => state.createPolicyApplicationCallStatus,
  (createPolicyApplicationCallStatus) => createPolicyApplicationCallStatus as CreatePolicyApplicationCallStatus,
)

export const policyApplicationSlice = createSlice({
  name: 'policyApplication',
  initialState: {
    createPolicyApplicationCallStatus: CreatePolicyApplicationCallStatus.Idle,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createPolicyApplication.fulfilled, () => {
      return {
        createPolicyApplicationCallStatus: CreatePolicyApplicationCallStatus.Success,
      }
    })
    builder.addCase(createPolicyApplication.pending, () => {
      return {
        createPolicyApplicationCallStatus: CreatePolicyApplicationCallStatus.Pending,
      }
    })
    builder.addCase(createPolicyApplication.rejected, () => {
      return {
        createPolicyApplicationCallStatus: CreatePolicyApplicationCallStatus.Error,
      }
    })
  },
})
