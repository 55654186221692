import { Brand } from '@lbg-protection/lib-ui-monoceros'
import { isBOS, isLloyds, isSW } from '../../../utils/brandSpecificFormatting'

export type Departments = 'ciClaims' | 'lifeClaims' | 'modelOffice' | 'callback'
type DepartmentNums = Record<Departments, string>
const sharedNumbers = {
  ciClaims: '03456014839',
  lifeClaims: '03456014179',
  modelOffice: '03456080378',
}

const departmentNumbers: Record<
  'halifax' | 'lloyds' | 'bos' | 'halifaxPublic' | 'lloydsPublic' | 'bosPublic' | 'sw' | 'swPublic',
  DepartmentNums
> = {
  halifax: {
    ...sharedNumbers,
    callback: '08001310554',
  },
  lloyds: {
    ...sharedNumbers,
    callback: '08001310556',
  },
  bos: {
    ...sharedNumbers,
    callback: '08001310553',
  },
  sw: {
    ...sharedNumbers,
    callback: '08000489355',
  },
  halifaxPublic: {
    ...sharedNumbers,
    callback: '08001310551',
  },
  lloydsPublic: {
    ...sharedNumbers,
    callback: '08001310552',
  },
  bosPublic: {
    ...sharedNumbers,
    callback: '08001310550',
  },
  swPublic: {
    ...sharedNumbers,
    callback: '08001310014',
  },
}

export const getBrandDepartmentNumber = (brand: Brand, department: Departments, isUnauth?: boolean): string => {
  if (isLloyds(brand)) {
    return isUnauth ? departmentNumbers.lloydsPublic[department] : departmentNumbers.lloyds[department]
  }
  if (isBOS(brand)) {
    return isUnauth ? departmentNumbers.bosPublic[department] : departmentNumbers.bos[department]
  }
  if (isSW(brand)) {
    return isUnauth ? departmentNumbers.swPublic[department] : departmentNumbers.sw[department]
  }
  return isUnauth ? departmentNumbers.halifaxPublic[department] : departmentNumbers.halifax[department]
}
