import { CreatePolicyApplicationRequestBody, UnAuthCreatePolicyApplicationRequestBody } from '../types'

export const cleanupPolicyRequest = (req: CreatePolicyApplicationRequestBody): CreatePolicyApplicationRequestBody => {
  if (req.printRequirements === null) {
    delete (req as Partial<CreatePolicyApplicationRequestBody>).printRequirements
  }

  if (req.continuousPaymentDetails.directDebitDueDay === null) {
    delete req.continuousPaymentDetails.directDebitDueDay
  }

  return req as CreatePolicyApplicationRequestBody
}

export const cleanupUnAuthPolicyRequest = (
  req: UnAuthCreatePolicyApplicationRequestBody,
): UnAuthCreatePolicyApplicationRequestBody => {
  if (req.printRequirements === null) {
    delete (req as Partial<UnAuthCreatePolicyApplicationRequestBody>).printRequirements
  }

  if (req.continuousPaymentDetails.directDebitDueDay === null) {
    delete req.continuousPaymentDetails.directDebitDueDay
  }

  return req as UnAuthCreatePolicyApplicationRequestBody
}
