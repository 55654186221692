import { CreatePolicyApplicationCallStatus } from '../types'

export const isCreatePolicyApplicationCallPending = (
  createPolicyApplicationCallStatus: CreatePolicyApplicationCallStatus,
) => {
  return createPolicyApplicationCallStatus === CreatePolicyApplicationCallStatus.Pending
}

export const wasCreatePolicyApplicationCallRejected = (
  createPolicyApplicationCallStatus: CreatePolicyApplicationCallStatus,
) => {
  return createPolicyApplicationCallStatus === CreatePolicyApplicationCallStatus.Error
}
