import { createSlice, PayloadAction, Reducer, Selector } from '@reduxjs/toolkit'
import { PaymentDetails } from './types'

const initialState: PaymentDetails = {
  accountNumber: '',
  sortCode: '',
  directDebitDay: '',
}

type Reducers = {
  setPaymentDetails: Reducer<PaymentDetails, PayloadAction<PaymentDetails>>
}

const reducers: Reducers = {
  setPaymentDetails: (state = initialState, action) => {
    const nextPaymentDetails = action.payload

    return { ...state, ...nextPaymentDetails }
  },
}

export const selectPaymentDetails: Selector<PaymentDetails, PaymentDetails> = (state) => state

export const paymentDetailsSlice = createSlice({
  name: 'paymentDetails',
  initialState,
  reducers,
})
