export type JourneyStep = {
  JourneyStep: string
  JourneyStepName: string
  ApplicationState?: string
  TargetPageName?: string
}

export type JourneyNavState = {
  journeyStep?: JourneyStep
  previousRoute?: string
}

export interface JourneyNavConfig {
  path: string
  state?: JourneyNavState
}

export const authjourneyNavConfig: JourneyNavConfig[] = [
  {
    path: '/landing-page',
    state: {
      journeyStep: {
        JourneyStep: '99',
        JourneyStepName: 'Indicative - Landing Page',
        ApplicationState: 'Product Information',
      },
    },
  },
  {
    path: '/about-you',
    state: {
      journeyStep: {
        JourneyStep: '4',
        JourneyStepName: 'About You',
        ApplicationState: 'Offered',
      },
    },
  },
  {
    path: '/product-information',
    state: {
      journeyStep: {
        JourneyStep: '21',
        JourneyStepName: 'Protection by Scottish Widows',
        ApplicationState: 'Application',
      },
    },
  },
  {
    path: '/your-health',
    state: {
      journeyStep: {
        JourneyStep: '22',
        JourneyStepName: 'Start Screen',
      },
    },
  },
  {
    path: '/your-health/declaration',
    state: {
      journeyStep: {
        JourneyStep: '23',
        JourneyStepName: 'Declaration Screen',
        ApplicationState: 'Application',
      },
    },
  },
  {
    path: '/your-health/questionnaire',
    state: {
      journeyStep: {
        JourneyStep: '24',
        JourneyStepName: 'UWME Question',
        ApplicationState: 'Application',
      },
    },
  },
  {
    path: '/eligibility-check',
    state: {
      journeyStep: {
        JourneyStep: '32',
        JourneyStepName: 'Loading results',
      },
    },
  },
  {
    path: '/quote',
    state: {
      journeyStep: {
        JourneyStep: '35',
        JourneyStepName: 'Your Quote',
        ApplicationState: 'Offered',
      },
    },
  },
  {
    path: '/payment-details',
    state: {
      journeyStep: {
        JourneyStep: '36',
        JourneyStepName: 'Payment Details',
        ApplicationState: 'Offered',
      },
    },
  },
  {
    path: '/special-requirements',
    state: {
      journeyStep: {
        JourneyStep: '37',
        JourneyStepName: 'Special Requirements',
        ApplicationState: 'Offered',
      },
    },
  },
  {
    path: '/marketing-preference',
    state: {
      journeyStep: {
        JourneyStep: '38',
        JourneyStepName: 'Marketing Preferences',
        ApplicationState: 'Offered',
      },
    },
  },
  {
    path: '/review-summary',
    state: {
      journeyStep: {
        JourneyStep: '38',
        JourneyStepName: 'Review Your Choices',
        ApplicationState: 'Offered',
      },
    },
  },
  {
    path: '/congrats',
    state: {
      journeyStep: {
        JourneyStep: '40',
        JourneyStepName: 'Congratulations',
        ApplicationState: 'Fulfilled',
      },
    },
  },
  {
    path: '/smoker-declaration',
    state: {
      journeyStep: {
        ApplicationState: 'Product Information',
        JourneyStep: '99',
        JourneyStepName: 'Indicative - Select Smoker',
      },
    },
  },
  {
    path: '/indicative-quote',
    state: {
      journeyStep: {
        JourneyStepName: 'Indicative - Quote',
        JourneyStep: '99',
        ApplicationState: 'Product Information',
      },
    },
  },
  {
    path: '/saved',
    state: {
      journeyStep: {
        JourneyStepName: 'Saved progress confirmation',
        JourneyStep: '89',
        ApplicationState: 'Offered',
      },
    },
  },
  {
    path: '/ineligible-age',
    state: {
      journeyStep: {
        JourneyStepName: 'Indicative - Not Eligible',
        JourneyStep: '99',
        ApplicationState: 'Application',
      },
    },
  },
  {
    path: '/ineligible',
    state: {
      journeyStep: {
        JourneyStepName: 'DECLINE Screen',
        JourneyStep: '25',
        ApplicationState: 'Declined',
      },
    },
  },
]

export const unauthjourneyNavConfig: JourneyNavConfig[] = [
  {
    path: '/landing-page',
    state: {
      journeyStep: {
        JourneyStep: '99',
        JourneyStepName: 'Indicative - Select Smoker',
        ApplicationState: 'Product Information',
        TargetPageName: 'Public Landing Page',
      },
    },
  },
  {
    path: '/customer-collection',
    state: {
      journeyStep: {
        JourneyStep: '4',
        JourneyStepName: 'About you',
        ApplicationState: 'Pre-Application',
        TargetPageName: 'About You - Public',
      },
    },
  },
  {
    path: '/product-information',
    state: {
      journeyStep: {
        JourneyStep: '21',
        JourneyStepName: 'Protection by Scottish Widows',
        ApplicationState: 'Application',
      },
    },
  },
  {
    path: '/your-health',
    state: {
      journeyStep: {
        JourneyStep: '22',
        JourneyStepName: 'Start Screen',
        ApplicationState: 'Application',
      },
    },
  },
  {
    path: '/your-health/declaration',
    state: {
      journeyStep: {
        JourneyStep: '23',
        JourneyStepName: 'Declaration Screen',
        ApplicationState: 'Application',
      },
    },
  },
  {
    path: '/your-health/questionnaire',
    state: {
      journeyStep: {
        JourneyStep: '24',
        JourneyStepName: 'UWME Question',
        ApplicationState: 'Application',
      },
    },
  },
  {
    path: '/eligibility-check',
    state: {
      journeyStep: {
        JourneyStep: '32',
        JourneyStepName: 'Loading results',
      },
    },
  },
  {
    path: '/quote',
    state: {
      journeyStep: {
        JourneyStep: '35',
        JourneyStepName: 'Your Quote',
        ApplicationState: 'Offered',
      },
    },
  },
  {
    path: '/payment-details',
    state: {
      journeyStep: {
        JourneyStep: '36',
        JourneyStepName: 'Payment Details',
        ApplicationState: 'Offered',
      },
    },
  },
  {
    path: '/special-requirements',
    state: {
      journeyStep: {
        JourneyStep: '37',
        JourneyStepName: 'Special Requirements',
        ApplicationState: 'Offered',
      },
    },
  },
  {
    path: '/marketing-preference',
    state: {
      journeyStep: {
        JourneyStep: '38',
        JourneyStepName: 'Marketing Preferences',
        ApplicationState: 'Offered',
      },
    },
  },
  {
    path: '/review-summary',
    state: {
      journeyStep: {
        JourneyStep: '38',
        JourneyStepName: 'Review Your Choices',
        ApplicationState: 'Offered',
      },
    },
  },
  {
    path: '/congrats',
    state: {
      journeyStep: {
        JourneyStep: '40',
        JourneyStepName: 'Congratulations',
        ApplicationState: 'Fulfilled',
      },
    },
  },
  {
    path: '/indicative-quote',
    state: {
      journeyStep: {
        JourneyStepName: 'Indicative - Quote',
        JourneyStep: '99',
        ApplicationState: 'Product Information',
      },
    },
  },
  {
    path: '/ineligible-age',
    state: {
      journeyStep: {
        JourneyStepName: 'Indicative - Not Eligible',
        JourneyStep: '99',
        ApplicationState: 'Application',
      },
    },
  },
  {
    path: '/ineligible',
    state: {
      journeyStep: {
        JourneyStepName: 'DECLINE Screen',
        JourneyStep: '25',
        ApplicationState: 'Declined',
      },
    },
  },
]
