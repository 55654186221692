import React from 'react'
import { journeyNavMapContext } from './journeyMapNavContext'
import { JourneyNavMap, EntryPoints } from './types'

const useJourneyNavMap = () => {
  const navMap = React.useContext(journeyNavMapContext)

  const getTargetNav = (key: keyof JourneyNavMap, defaultTarget: string | EntryPoints): string | EntryPoints => {
    return navMap && navMap[key] ? navMap[key] : defaultTarget
  }

  return {
    navMap,
    getTargetNav,
  }
}

export { useJourneyNavMap }
