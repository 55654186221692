import { authJourneyApi } from '../../apis/authJourneyApi'
import { unAuthJourneyApi } from '../../apis/unAuthJourneyApi'
import { AddressInterface } from '../../journeys/purchase'
import { UK_COUNTRYCODE } from '../CustomerCollectionPage/components/constants'
import { MarketingPreferencesState } from '../MarketingPreferencesPage/types'
import { transformDobForPricing } from '../QuotePage/utils'
import {
  ApplicantParams,
  BuildApplicantRequest,
  BuildPolicyRequestBody,
  BuildUnAuthApplicantRequest,
  CreatePolicyApplicationParams,
  CreatePolicyApplicationRequestBody,
  UnAuthBuildPolicyRequestBody,
  UnAuthCreatePolicyApplicationRequestBody,
} from './types'
import { getContactDetails, getMarketingPreferences, getPolicies, getPrintEnum, cleanupPolicyRequest } from './utils'
import { cleanupUnAuthPolicyRequest } from './utils/cleanupPolicyRequest'

const buildMarketPreferences = (marketingPreferences: MarketingPreferencesState) => {
  return {
    deviceNotifications: getMarketingPreferences('deviceNotifications', marketingPreferences),
    phone: getMarketingPreferences('phone', marketingPreferences),
    email: getMarketingPreferences('email', marketingPreferences),
    mail: getMarketingPreferences('post', marketingPreferences),
    sms: getMarketingPreferences('textMessages', marketingPreferences),
    websiteScottishWidows: getMarketingPreferences('scottishWidowsWebsites', marketingPreferences),
  }
}

const buildAddress = (address?: AddressInterface) => {
  const { addressLines, buildingName, buildingNumber, county, district, postCode, postTown, subBuildingName } =
    address || {}
  return {
    addressLines: addressLines || [],
    postcode: postCode || '',
    postTown: postTown || '',
    county: county || '',
    buildingNumber: buildingNumber || '',
    buildingName: buildingName || '',
    subBuildingName: subBuildingName || '',
    district: district || '',
  }
}

const buildCommonApplicantDetails = (applicant: ApplicantParams) => {
  const { customerDetails, marketingPreferences, address, underwriteMe } = applicant

  return {
    title: customerDetails?.title ?? '',
    forename: customerDetails?.firstName ?? '',
    surname: customerDetails?.lastName ?? '',
    dateOfBirth: customerDetails?.dateOfBirth ? transformDobForPricing(customerDetails.dateOfBirth) : '',
    marketingPreferences: buildMarketPreferences(marketingPreferences),
    address: buildAddress(address),
    enquiryId: underwriteMe.enquiryId,
  }
}

const buildApplicant: BuildApplicantRequest = (applicant: ApplicantParams) => {
  const { customerDetails } = applicant
  return {
    ...buildCommonApplicantDetails(applicant),
    contactDetails: getContactDetails(customerDetails),
  }
}

const buildUnauthApplicant: BuildUnAuthApplicantRequest = (applicant: ApplicantParams) => {
  const { customerDetails } = applicant
  const { email, telephone } = customerDetails || {}
  return {
    ...buildCommonApplicantDetails(applicant),
    nationalities: ['NOT_USED'],
    emailAddress: email || '',
    telephone: {
      phoneNumber: telephone || '',
      countryCode: UK_COUNTRYCODE,
    },
  }
}

const buildCommonPolicyRequestBody = (params: CreatePolicyApplicationParams) => {
  const { lastQuoteDate, lifeCover, bodyCover, paymentDetails, specialRequirement } = params
  const persistStoredQuoteDate = lastQuoteDate ? { persistStoredQuoteDate: true } : {}

  return {
    pricingRequestDetails: {
      policies: getPolicies(lifeCover, bodyCover),
      ...persistStoredQuoteDate,
    },
    printRequirements: getPrintEnum(specialRequirement),
    continuousPaymentDetails: {
      accountNumber: paymentDetails?.accountNumber ?? '',
      sortCode: paymentDetails?.sortCode.replace(/-/g, '') ?? '',
      directDebitDueDay: paymentDetails?.directDebitDay ? Number(paymentDetails?.directDebitDay) : null,
    },
  }
}

const buildPolicyRequestBody: BuildPolicyRequestBody = (params) => {
  const { address, customerDetails, marketingPreferences, ocisId, underwriteMe } = params

  return cleanupPolicyRequest({
    partyId: ocisId ?? '',
    applicant: buildApplicant({
      address,
      customerDetails,
      marketingPreferences,
      underwriteMe,
    }),
    ...buildCommonPolicyRequestBody(params),
  } as CreatePolicyApplicationRequestBody) as CreatePolicyApplicationRequestBody
}

const buildUnAuthPolicyRequestBody: UnAuthBuildPolicyRequestBody = (params: CreatePolicyApplicationParams) => {
  const { marketingPreferences, underwriteMe, address, customerDetails } = params
  return cleanupUnAuthPolicyRequest({
    applicant: buildUnauthApplicant({
      address,
      customerDetails,
      marketingPreferences,
      underwriteMe,
    }),
    ...buildCommonPolicyRequestBody(params),
  } as UnAuthCreatePolicyApplicationRequestBody) as UnAuthCreatePolicyApplicationRequestBody
}

const createPolicyApplication = async (requestBody: CreatePolicyApplicationParams): Promise<void> => {
  if (requestBody.isJourneyUnAuth) {
    const body = buildUnAuthPolicyRequestBody(requestBody)
    await unAuthJourneyApi().createPolicyApplication(body)
  } else {
    const body = buildPolicyRequestBody(requestBody)
    await authJourneyApi().createPolicyApplication(body)
  }
}

export const policyApi = {
  buildPolicyRequestBody,
  createPolicyApplication,
  buildUnAuthPolicyRequestBody,
}
