import {
  ApplicantV2,
  CreatePolicyApplicationRequestBodyV2 as CreatePolicyApplicationRequestBody,
} from '@lbg-protection/api-client-nmp-journey'
import {
  Applicant as UAApplicant,
  CreatePolicyApplicationRequestBody as UnAuthCreatePolicyApplicationRequestBody,
} from '@lbg-protection/api-client-public-site-journey'
import { AddressInterface } from '../../journeys/purchase/index'
import { AboutYouApplicantDetails } from '../../journeys/purchase/aboutYou.slice'
import { PaymentDetails } from '../PaymentDetailsPage/types'
import { CoverSummaryInterface } from '../Shared/Basket/CoverSummary/types'
import { MarketingPreferencesState } from '../MarketingPreferencesPage/types'

export {
  PolicyApplicationPricingRequestDetailsV2 as PolicyApplicationPricingRequestDetails,
  PricingRequestBodyV2 as PricingRequestBody,
} from '@lbg-protection/api-client-nmp-journey'
export { CreatePolicyApplicationRequestBody }
export { UnAuthCreatePolicyApplicationRequestBody }

export enum CreatePolicyApplicationCallStatus {
  Pending = 'Pending',
  Success = 'Success',
  Idle = 'Idle',
  Error = 'Error',
}

export interface SummaryPageProps {
  lifeCover?: CoverSummaryInterface
  bodyCover?: CoverSummaryInterface
  customerDetails?: AboutYouApplicantDetails
  specialRequirement: string | null
  address?: AddressInterface
  paymentDetails?: PaymentDetails
  marketingPreferences: MarketingPreferencesState
  createPolicyApplicationCallStatus: CreatePolicyApplicationCallStatus
  ocisId?: string
  enquiryId?: string
}

export interface ReviewSummaryPageProps extends SummaryPageProps {
  createPolicyApplication: (params: CreatePolicyApplicationParams) => Promise<CreatePolicyResponse>
}

export interface CreatePolicyResponse {
  meta: { arg: CreatePolicyApplicationParams; requestId: string }
  payload: number
  type: string
}

interface UnderwriteMe {
  enquiryId: string
}
export interface CreatePolicyApplicationParams {
  lifeCover?: CoverSummaryInterface
  bodyCover?: CoverSummaryInterface
  customerDetails?: AboutYouApplicantDetails
  specialRequirement: string | null
  address?: AddressInterface
  paymentDetails?: PaymentDetails
  marketingPreferences: MarketingPreferencesState
  ocisId?: string
  underwriteMe: UnderwriteMe
  lastQuoteDate?: string
  isJourneyUnAuth: boolean
}

export interface ApplicantParams {
  address?: AddressInterface
  customerDetails?: AboutYouApplicantDetails
  marketingPreferences: MarketingPreferencesState
  underwriteMe: UnderwriteMe
}

export type Applicant = ApplicantV2
export type UnAuthApplicant = UAApplicant

export type BuildPolicyRequestBody = (args: CreatePolicyApplicationParams) => CreatePolicyApplicationRequestBody
export type UnAuthBuildPolicyRequestBody = (
  args: CreatePolicyApplicationParams,
) => UnAuthCreatePolicyApplicationRequestBody

export type BuildApplicantRequest = (args: ApplicantParams) => Applicant
export type BuildUnAuthApplicantRequest = (args: ApplicantParams) => UnAuthApplicant
