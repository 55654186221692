import React from 'react'
import { Box, makeStyles, MonocerosTheme, Brand } from '@lbg-protection/lib-ui-monoceros'
import { isLloyds, isSW } from '../../../utils/brandSpecificFormatting'
import { useJourneyChecker } from '../../../journeyCwaConfig'
import { PhoneCard } from '../PhoneCard/PhoneCard'
import { FooterTextBox } from '../FooterTextBox/FooterTextBox'

const styles = makeStyles((theme: MonocerosTheme) => {
  const isSWBrand = isSW(theme.name as Brand)
  const pageLayout = {
    display: 'flex',
    flexDirection: 'column' as 'column' | 'row',
    width: '100%',
    justifyContent: 'start',
  }

  return {
    '@global': {
      html: { height: '100%' },
      body: { height: '100%' },
      '#root': { display: 'flex', flexDirection: 'column', flex: 1, height: '100%', alignItems: 'center' },
      'input[type=number]': {
        '-moz-appearance': 'textfield',
      },
    },

    pageContainerNoBackground: {
      ...pageLayout,
    },
    pageContainerWithBackground: {
      ...pageLayout,
    },
    headerTitle: {
      color: theme.palette.common.white,
    },
    paddingRightBig: {
      paddingRight: '3rem',
    },
    accordionContainer: {
      margin: `${theme.spacing(4)}  ${isSWBrand ? '0.75rem' : '0'}`,
    },
    back: {
      backgroundColor: 'white',
    },
    phoneCard: {
      paddingTop: isLloyds(theme.name as Brand) ? theme.spacing(2) : 0,
      marginBottom: isSWBrand ? '1.875rem' : 0,
    },
  }
})

interface ScreenTemplateProps {
  children: React.ReactNode
  bottomNav?: React.ReactNode
  actions?: React.ReactNode
  backgroundImagePath?: string
  showPhoneCard?: boolean
  additionalInfo?: React.ReactNode
  accordionGroup?: React.ReactNode
  dataTestId?: string
  pageHeader?: React.ReactNode
  additionalInfoTiered?: React.ReactNode
  showFooterText?: boolean
}

const ScreenTemplate = (props: ScreenTemplateProps) => {
  const classes = styles()
  const { children, bottomNav, additionalInfo, accordionGroup, pageHeader, showFooterText } = props
  const { isJourneyUnAuth, isJourneyWeb } = useJourneyChecker()

  return (
    <div
      className={props.backgroundImagePath ? classes.pageContainerWithBackground : classes.pageContainerNoBackground}
    >
      {pageHeader && <Box>{pageHeader}</Box>}
      <div>
        <div data-testid={props.dataTestId ?? 'page-main-body'}>
          {children}
          {bottomNav}
          {additionalInfo}
          {accordionGroup && <Box className={classes.accordionContainer}>{accordionGroup}</Box>}
          {props.showPhoneCard && <Box className={classes.phoneCard}>{props.showPhoneCard && <PhoneCard />}</Box>}
          {!isJourneyWeb && !isJourneyUnAuth && showFooterText && <FooterTextBox />}
        </div>
      </div>
    </div>
  )
}

ScreenTemplate.defaultProps = {
  showPhoneCard: true,
}

export default ScreenTemplate
