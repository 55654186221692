import React, { useEffect, useState, useCallback } from 'react'
import debounce from 'lodash.debounce'
import { Controller, useForm } from 'react-hook-form'
import { PricingRequestBody } from '@lbg-protection/api-client-nmp-journey'
import {
  Box,
  Brand as BrandTheme,
  CurrencyInputWithStepper,
  FormField,
  MonocerosTheme,
  NumberInputWithStepper,
  Typography,
  useTheme,
} from '@lbg-protection/lib-ui-monoceros'
import clsx from 'clsx'
import formatCurrency, { formatCurrencyWithZeroesAfterDecimal } from '../../../../utils/formatCurrency'
import { calculateMaxTerm } from '../../../../utils/calculateMaxTerm'
import * as c from '../../../QuotePage/QuoteCard/Eligible/constants'
import { shouldShowMonthlyCost } from '../../../QuotePage/QuoteCard/Eligible/utils'
import {
  amountValidation,
  minTerm,
  termValidator,
  minAmount,
  maxAmount,
} from '../../../QuotePage/QuoteCard/Eligible/validators'
import { BasePricingRequestParams } from '../../types'
import { AdjustedValueConfirmation } from '../AdjustedValueConfirmation'
import { IndicativeQuoteCardProps } from '../types'
import { withStyles } from './styles'
import { QuoteCardOnSubmitInterface } from './types'
import { tagPricing } from './tags'
import { isSW } from '../../../../utils/brandSpecificFormatting'

export const IndicativeQuoteCardContent = React.memo((props: IndicativeQuoteCardProps) => {
  const { name: themeName = BrandTheme.HALIFAX2020 } = useTheme<MonocerosTheme>()
  const isSWBrand = isSW(themeName)
  const [adjustedDismissed, setAdjustedDismissed] = useState<boolean>(false)
  const smokerStatus = props.smokerStatus !== null ? props.smokerStatus : false

  const { errors, getValues, control, setValue, trigger } = useForm<QuoteCardOnSubmitInterface>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      amount: props.coverSummary.amount,
      term: props.coverSummary.term,
      monthlyCost: props.coverSummary.monthlyCost,
    },
  })

  const buildPricingRequestParamsByCalculationType = (
    calculationType: PricingRequestBody.CalculationTypeEnum,
  ): BasePricingRequestParams => {
    if (calculationType === PricingRequestBody.CalculationTypeEnum.SUMASSURED) {
      const { amount, term } = getValues()

      return {
        dob: props.dob,
        coverTerm: term,
        sumAssured: amount,
        coverType: props.coverSummary.coverType,
        smokerStatus,
        calculationType: PricingRequestBody.CalculationTypeEnum.SUMASSURED,
      }
    }
    const { term, monthlyCost } = getValues()
    return {
      dob: props.dob,
      coverTerm: term,
      monthlyPremium: monthlyCost,
      coverType: props.coverSummary.coverType,
      smokerStatus,
      calculationType: PricingRequestBody.CalculationTypeEnum.PREMIUM,
    }
  }
  const makePricingCallForSumAssured = async () => {
    if (props.callPricingAPI) {
      await props.callPricingAPI(
        buildPricingRequestParamsByCalculationType(PricingRequestBody.CalculationTypeEnum.SUMASSURED),
      )
    }
  }

  const makePricingCall = () => {
    if (props.callPricingAPI) {
      const { amount, term } = getValues()
      const { amount: amountError, term: termError, monthlyCost: costError } = errors
      if (term && !amountError && !termError && !costError) {
        setAdjustedDismissed(false)
        if (amount) {
          makePricingCallForSumAssured()
        }
      }
    }
  }

  const acceptAdjusted = () => {
    if (props.onUpdate) {
      const adjustedCover = {
        ...props.coverSummary,
        amount: props.coverSummary.adjustedAmount ?? 0,
        monthlyCost: props.coverSummary.adjustedMonthlyCost ?? 0,
      }
      props.onUpdate(adjustedCover)
    }
  }

  const updateCover = async (field: keyof QuoteCardOnSubmitInterface) => {
    if (props.onUpdate) {
      const valid = await trigger(field)
      if (getValues()[field] !== props.coverSummary[field]) {
        if (valid) {
          const { amount, term, monthlyCost } = getValues()
          const updatedCover = {
            ...props.coverSummary,
            amount,
            term,
            monthlyCost: monthlyCost === undefined ? props.coverSummary.monthlyCost : monthlyCost,
          }
          props.onUpdate(updatedCover)
          makePricingCall()
        }
      }
    }
  }

  const debouncedUpdateCover = useCallback(debounce(updateCover, 2000), [])
  const maxTerm = calculateMaxTerm(props.dob)

  const classes = withStyles({})

  useEffect(() => {
    if (props.callPricingAPI) {
      const { amount, term } = props.coverSummary
      const { amount: amountError, term: termError } = errors
      if (term && !amountError && !termError) {
        setAdjustedDismissed(false)
        if (amount) {
          props.callPricingAPI(
            buildPricingRequestParamsByCalculationType(PricingRequestBody.CalculationTypeEnum.SUMASSURED),
          )
        }
      }
    }
  }, [])

  useEffect(() => {
    if ((props.numberOfPricingCalls ?? 0) > 0) {
      tagPricing(props.coverSummary)
    }
  }, [props.numberOfPricingCalls])

  useEffect(() => {
    setValue('amount', props.coverSummary.amount)
    setValue('term', props.coverSummary.term)
    setValue('monthlyCost', props.coverSummary.monthlyCost)
  }, [props.coverSummary])

  const upCurrencyLabelProvider = (coverType: PricingRequestBody.CoverTypeEnum) => (
    stepperValue: string | undefined,
  ) => {
    if (stepperValue !== undefined) {
      return `Increase ${coverType} Cover amount by ${stepperValue} pound${stepperValue === '1' ? '' : 's'}`
    }
    return `for Increase ${coverType} cover amount`
  }

  const downCurrencyLabelProvider = (coverType: PricingRequestBody.CoverTypeEnum) => (
    stepperValue: string | undefined,
  ) => {
    if (stepperValue !== undefined) {
      return `Decrease ${coverType} Cover amount by ${stepperValue} pound${stepperValue === '1' ? '' : 's'}`
    }
    return `for Decrease ${coverType} cover amount`
  }

  const upDurationLabelProvider = (coverType: PricingRequestBody.CoverTypeEnum) => (
    stepperValue: string | undefined,
  ) => {
    if (stepperValue !== undefined) {
      return `Increase ${coverType} Cover duration by ${stepperValue} year${stepperValue === '1' ? '' : 's'}`
    }
    return `for Increase ${coverType} cover duration`
  }

  const downDurationLabelProvider = (coverType: PricingRequestBody.CoverTypeEnum) => (
    stepperValue: string | undefined,
  ) => {
    if (stepperValue !== undefined) {
      return `Decrease ${coverType} Cover duration by ${stepperValue} year${stepperValue === '1' ? '' : 's'}`
    }
    return `for Decrease ${coverType} cover duration`
  }

  return (
    <Box className={classes.wrapper}>
      <form className={classes.formWrapper}>
        <Box width="100%" className={clsx(isSWBrand && classes.gapBetweenFields)}>
          <Controller
            name={c.amountName}
            control={control}
            rules={amountValidation}
            render={({ onChange, onBlur, value, ref }) => (
              <FormField
                inputRef={ref}
                fullWidth
                id={`${props.coverSummary.coverType}-amount`}
                label={c.amountLabel}
                helperText={`Up to ${formatCurrency('en-GB', 'GBP', maxAmount)}`}
                className={classes.input}
                errorMessage={errors?.amount?.message}
                value={value ? value : ''} // eslint-disable-line no-unneeded-ternary
                onChange={onChange}
                onBlur={() => {
                  onBlur()
                  updateCover('amount')
                }}
              >
                <CurrencyInputWithStepper
                  decimalScale={0}
                  min={minAmount}
                  max={maxAmount}
                  firstStep={{
                    up: 5000,
                    down: 0,
                  }}
                  data-testid={`${props.coverSummary.coverType}-amount`}
                  inputProps={{ 'aria-required': true }}
                  step={5000}
                  snapToStep={false}
                  onStepEnd={() => {
                    debouncedUpdateCover('amount')
                  }}
                  placeholder="Enter amount"
                  className={classes.inputPlaceholder}
                  spellCheck={false}
                  stepLabelProvider={{
                    upLabelProvider: upCurrencyLabelProvider(props.coverSummary.coverType),
                    downLabelProvider: downCurrencyLabelProvider(props.coverSummary.coverType),
                  }}
                  stepperButtonProps={{ highlightOnError: !!isSWBrand }}
                />
              </FormField>
            )}
          />
        </Box>
        <Box width="100%" className={clsx(isSWBrand && classes.marginBottom10)}>
          <Controller
            name={c.termName}
            control={control}
            rules={termValidator(maxTerm)}
            render={({ onChange, onBlur, value, ref }) => (
              <FormField
                inputRef={ref}
                fullWidth
                id={`${props.coverSummary.coverType}-term`}
                label={c.termLabel}
                helperText={`From 5 to  ${maxTerm} years`}
                className={classes.input}
                errorMessage={errors?.term?.message}
                value={value ? value : ''} // eslint-disable-line no-unneeded-ternary
                onChange={onChange}
                onBlur={() => {
                  onBlur()
                  updateCover('term')
                }}
              >
                <NumberInputWithStepper
                  decimalScale={0}
                  min={minTerm}
                  max={maxTerm}
                  data-testid={`${props.coverSummary.coverType}-term`}
                  inputProps={{ 'aria-required': true }}
                  step={1}
                  onStepEnd={() => {
                    debouncedUpdateCover('term')
                  }}
                  placeholder="Enter duration"
                  className={classes.inputPlaceholder}
                  spellCheck={false}
                  stepLabelProvider={{
                    upLabelProvider: upDurationLabelProvider(props.coverSummary.coverType),
                    downLabelProvider: downDurationLabelProvider(props.coverSummary.coverType),
                  }}
                  stepperButtonProps={{ highlightOnError: !!isSWBrand }}
                />
              </FormField>
            )}
          />
        </Box>
        {shouldShowMonthlyCost(props.pricingCallStatus, getValues().monthlyCost) && (
          <>
            {props.coverSummary.adjustedMonthlyCost && !adjustedDismissed ? (
              <AdjustedValueConfirmation message={c.adjustedMonthlyCostMsg} onSubmit={acceptAdjusted} />
            ) : (
              <Box width="100%">
                <Box className={classes.monthlyPremiumText} data-testid={`${props.coverSummary.coverType}-label`}>
                  <Typography variant="subtitle2">{c.costLabel}</Typography>
                </Box>
                <Box className={classes.monthlyPremiumText}>
                  <Typography variant="h2" data-testid={`${props.coverSummary.coverType}-value`}>
                    {props.coverSummary?.monthlyCost
                      ? formatCurrencyWithZeroesAfterDecimal('en-GB', 'GBP', props.coverSummary?.monthlyCost)
                      : ''}
                  </Typography>
                </Box>
              </Box>
            )}
          </>
        )}
      </form>
    </Box>
  )
})
